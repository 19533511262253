import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Routes } from 'src/app/routes/routes';
import { GridService } from 'src/app/services/grid.service';

@Component({
  selector: 'inventory-movement-form',
  templateUrl: './inventory-movement-form.component.html',
  styleUrls: ['./inventory-movement-form.component.scss']
})
export class InventoryMovementFormComponent implements OnInit {

  public title          = "Movimentação de Estoque";
  public dataItem:any   = {};
  public closeModal     = false;
  public resetForm      = false;
  public loader = false;

  public tab = {
    goal: {
      disabled: true,
      read: false
    }
  }
  public comboType = null;
  public form      = null;
  public grid      = null;

  @ViewChild("tabs") tabs:MatTabGroup;

  constructor(
    public dialog:MatDialogRef<InventoryMovementFormComponent>=null,
    @Inject(MAT_DIALOG_DATA) public data=null,
    public gridService: GridService
  ){ 

    if(data != null){
      if(data.dataItem != null){
        if(data.actionForm == "create"){
          this.initDataItem();
        }else{
          this.dataItem = data.dataItem;
        }  
      }else{
        this.initDataItem();
      }
    }else{
      this.initDataItem();
    }  
    
    this.setDataParams();

  }
  /**
   * 
   * Set data params
   * 
   */
  setDataParams(){
    
    if(typeof(this.data.dataParams) != "undefined"){ 

      if(typeof(this.data.dataParams.product_id) != "undefined"){
        this.dataItem.product_id = this.data.dataParams.product_id;
      }
      if(typeof(this.data.dataParams.value_cost) != "undefined"){
        this.dataItem.value_cost = this.data.dataParams.value_cost;
      } 
      if(typeof(this.data.dataParams.form) != "undefined"){
        this.form = this.data.dataParams.form;
      } 

    } 
    if(typeof(this.data.params) != "undefined"){

      if(typeof(this.data.params.product_id) != "undefined"){
        this.dataItem.product_id = this.data.params.product_id;
      }
      if(typeof(this.data.params.value_cost) != "undefined"){
        this.dataItem.value_cost = this.data.params.value_cost;
      }
      if(typeof(this.data.params.grid) != "undefined"){
        this.grid = this.data.params.grid;
      }

    } 

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      product_id: "",
      quantity: 0,
      inventory_movement_type_id: null,
      value_cost: "",
      _t_title: ""
    }
    this.data.actionForm = "create"; 

    this.setDataParams();
    
  }
   /**
   * 
   * Save DATA
   * 
   */
  save(close=false,resetForm){
    
    this.closeModal = close;
    if(this.form == "product"){
      this.closeModal = true; 
    }
    this.resetForm  = resetForm;
    this.data.save(this);

  }
  /**
   * 
   * 
   */
  saveSuccess(){ 

    this.gridService.updateGrid.emit(this.grid); 

  }  
  /**
   * 
   * Muda as TABS
   * 
   */
   changeTabs(tab){
    
    if(tab.tab.textLabel == "Metas"){
      this.tab.goal.read = true; 
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.comboType = Routes.inventory.inventory_movement_type.combo;
  }

}
