<div class="modal-form">
    <form-title [form]="this"></form-title>
    <form-toolbar [form]="this"></form-toolbar>
    <div class="modal-form-content">
        <form autocomplete="'off'"> 
            <div class="row"> 
                <div class="col-md-4">  
                    <dm-combo [text]="dataItem._t_title" [url]="comboType" [(ngModel)]="dataItem.inventory_movement_type_id" label="Tipo de Movimentação *:" name="inventory_movement_type_id"></dm-combo>
                </div>
                <div class="col-md-4"> 
                    <dm-input type="number" label="Quantidade*:" [(ngModel)]="dataItem.quantity" name="quantity"></dm-input>
                </div>
                <div class="col-md-4">  
                    <dm-input type="money" label="Valor de Custo:" [(ngModel)]="dataItem.value_cost" name="value_cost"></dm-input>
                </div>   
            </div>  
            <hr> 
            <div class="row">
                <div class="col-md-12">
                    <dm-input type="textarea" label="Observação:" [(ngModel)]="dataItem.observation" name="observation" rows="5"></dm-input>
                </div>    
            </div>    
        </form>
    </div>
</div>
<loader *ngIf="loader"></loader>